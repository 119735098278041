:root {
  --yellow: #fffbdb;
  --blue: #224870;
  --red: #a3333d;
  --orange: #ff7f51;
  --light-blue: #00a6ed;
  --light-yellow: #fffdf1;
  --pink: #D42990;
  --elevation-small: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --elevation-high: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px, rgba(0, 0, 0, 0.24) 0px 3px 8px;

  --header-height: calc(48px + 2 * 10px);
  --footer-height: 20px;

  --sans: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

@font-face {
  font-family: Literata;
  src: url("../assets/Literata.ttf");
}

@font-face {
  font-family: Milonga;
  src: url("../assets/Milonga-Regular.ttf");
}

body {
  font-family: var(--sans);
  background-color: var(--light-yellow);
}

/* HEADER */
.home {
  text-decoration: none;
  color: var(--blue);
  flex-shrink: 0;
}
header {
  background: var(--yellow);
  color: var(--blue);

}
.header-wrapper h1 {
  font-family: Milonga;
  margin: 0px;
  width: 100%;
  text-align: center;
}
.header-wrapper h1 span {
  font-size: 0.75em;
  white-space: pre;
}
.header-wrapper {
  padding: 10px 32px;
  max-width: 1200px;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 0.5rem;
  align-items: center;
  position: relative;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 8px;
}


.searchbar {
  padding: 4px;
  padding-right: 36px;

  background-color: white;

  border: 2px solid darkgray;
  border-radius: 6px;

  width: 100%;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='16' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z' fill='currentColor'/%3E%3C/svg%3E");
  background-position: 97%;
  background-repeat: no-repeat;

  &:focus {
    outline: none;
    border: 2px solid var(--blue);
  }
}

/* MAIN */
main {
  /* min-height: calc(100dvh - var(--header-height)); */
  /* padding-top: var(--header-height); */
  /* padding-bottom: 4rem; */
}

.magnifier {
  border: none;
  background: none;
  cursor: pointer;
  color: var(--blue);
}