
.cardbox {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: 16px;
    padding: 20px 32px;
    max-width: 1200px;
    margin-inline: auto;
}

.card {
    display: inline-flex;
    padding: 16px;
    min-height: 96px;

    flex-direction: column;
    justify-content: space-between;

    /* border-color: lightgray; */
    background: white;
    color: var(--blue);
    border: none;
    border-radius: 16px;
    box-shadow: var(--elevation-small);

    text-decoration: none;
    transition: all 300ms;

}

.card:hover {
    box-shadow: var(--elevation-high);
}

.title {
    margin: 0 0 4px 0;
    line-height: 1em;
    letter-spacing: -0.0375em;
    text-wrap: balance;
}

.band {
    font-family: Milonga;
    line-height: 1.5;
    display: flex;
}

.band svg {
    width: 1.5em;
    height: 1.5em;
    color: var(--blue);
    padding-left: 0.5em;
    display: inline;
    flex-shrink: 0;
}
