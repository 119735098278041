
.layout {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 32px;
}

.sheet {
    --columns: 1;
    font-family: var(--sans);
    font-weight: 500;
    font-size: 1.125rem;
    column-count: var(--columns);
}

@media (max-width: 900px) {
    .sheet {
        column-count: max(1, calc(var(--columns) - 1));
    }
}

@media (max-width: 600px) {
    .sheet {
        column-count: 1;
    }
}

.chords {
    font-family: var(--sans);
    color: var(--pink);
    font-weight: 600;
}

.highlight {
    text-decoration: underline;
}

.head {
    margin: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.head h2 {
    font-size: 4rem;
    display: inline-block;
    margin: 0 2rem 0 0;
}

.head h3 {
    font-size: 3rem;
    display: inline-block;
    font-weight: 400;
    margin: 0 1rem 0 0;
}

.transpose {
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.transpose-button {
    display: flex;
    background-color: var(--pink);
    color: white;
    border-radius: 100px;
    padding: 8px;
    margin: 0.5rem;
    font-weight: 500;
    border: none;
    width: 3em;
    height: 3em;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
}

.transpose .chords {
    min-width: 7.5rem;
    font-size: 1.5rem;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
}

.icon {
    width: 3rem;
    margin: 8px;
}

.homebutton {
    position: fixed;
    z-index: 10;
}

@media print {
    .homebutton {
        display: none;
    }

    .transpose {
        display: none !important;
    }

    .head h2 {
        font-size: 2.5rem;
    }

    .head h3 {
        font-size: 1.5rem;
    }
}

.chordblock {
    display: inline-grid;
    grid-template-rows: 1fr 1fr;
    white-space: pre;
}

.chordblock .chords {
    padding-right: 0.5em;
    grid-row: 1;
    user-select: none;
}

.line>* {
    vertical-align: bottom;
}

.line.empty .chordblock {
    grid-template-rows: 1fr;
}

.chord-grid {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
    position: relative;
    user-select: none;
}

.chord-bar {
    border-left: 2px solid var(--pink);
    padding: 0 0.5em;
    display: grid;
    grid-auto-flow: column;
    position: relative;
}

.chord-bar:last-child,
.bar-eorep:last-child {
    border-right: 2px solid var(--pink);
}

.block {
    break-inside: avoid;
    margin-bottom: 1em;
}

.block .line + .line {
    margin-top: 0.25em;
}

.chorus {
    padding-left: 2em;
    border-left: 2px solid var(--blue);
}

.chord-definition {
    font-style: italic;
}

/* last double bar of the line */
.bar-double, .bar-eorep {
    position: absolute;
    height: 100%;
    right: 0;
}

.chord-bar.bar-double {
    position: relative;
    border-left: 2px solid transparent;
}

.bar-double::after {
    content: "";
    position: absolute;
    border-left: 2px solid var(--pink);
    height: 100%;
    left: 0px;
}

.bar-double::before {
    content: "";
    position: absolute;
    border-left: 2px solid var(--pink);
    height: 100%;
    left: -4px;
}

.chord-bar.bar-eorep {
    position: relative;
    border-left: 2px solid transparent;
}

.bar-sorep::before, .bar-esorep::before {
    content: ":";
    position: absolute;
    color: var(--pink);
    font-weight: 600;
    top: -0.125em;
    left: 1px;
}

.bar-eorep::after, .bar-esorep::after {
    content: ":";
    position: absolute;
    color: var(--pink);
    font-weight: 600;
    top: -0.125em;
    left: -7px;
}